<template>
  <Homepage />
  <!-- <router-view></router-view> -->
</template>

<script>
import Homepage from './components/homepage.vue';
//import licenseSecrecy from './components/licenseSecrecy.vue';
//import licenseService from './components/licenseService.vue';

export default {
  name: 'App',
  components: {
    Homepage,
  }
};
</script>

<style>

</style>
