import { createRouter, createWebHistory } from 'vue-router';
import Homepage from '../components/homepage.vue';
import LicenseSecrecy from '../components/licenseSecrecy.vue';
import LicenseService from '../components/licenseService.vue';

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage,
  },
  {
    path: '/license-secrecy',
    name: 'LicenseSecrecy',
    component: LicenseSecrecy,
  },
  {
    path:'/license-service',
    name:'LicenseService',
    component:LicenseService,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
