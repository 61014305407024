<template>
  <div class="iframe-container">
    <iframe src="/document/隐私政策V1.pdf#zoom=125"></iframe>
  </div>
</template>

<script>
export default {
  name: 'LicenseSecrecy'
};
</script>

<style>
/* 样式 */
.iframe-container {
  width: 100%;
  height: 100vh; /* 高度适配屏幕高度 */
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe {
  width: 100%; /* 宽度占满容器 */
  height: 100%; /* 高度占满容器 */
  border: none; /* 移除默认边框 */
}
</style>
